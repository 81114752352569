const DEFAULT_OMIT_CONTACT_RESELLER = { omitContactReseller: false };

export const RESELLER_IDS = {
	NPL: 'NPL',
	RTLXL: 'RTLXL',
	KPN: 'KPN',
	ZIGGO: 'ZIGGO',
	TMNL: 'TMNL',
	APPLE: 'APPLE',
	GOOGLE: 'GOOGLE',
	SAMSUNG: 'SAMSUNG',
	DELTA: 'DELTA',
	TMOBILE_GOGOGODEALS: 'TMOBILE_GOGOGODEALS',
	RTL: 'RTL',
};

export const TRUSTED_PARTY_IDS = {
	TMOBILE: 'tmobile',
	RTL: 'rtl',
	KPN: 'kpn',
	ZIGGO: 'ziggo',
	DELTA: 'delta',
};

export const NPL = {
	id: RESELLER_IDS.NPL,
	displayName: 'de Nationale Postcode Loterij',
	...DEFAULT_OMIT_CONTACT_RESELLER,
};

export const RTLXL = {
	id: RESELLER_IDS.RTLXL,
	displayName: 'RTLXL',
	...DEFAULT_OMIT_CONTACT_RESELLER,
};

export const SAMSUNG = {
	id: RESELLER_IDS.SAMSUNG,
	displayName: 'Samsung',
	omitContactReseller: true,
};

export const TMOBILE = {
	id: RESELLER_IDS.TMNL,
	trustedPartyId: TRUSTED_PARTY_IDS.TMOBILE,
	displayName: 'Odido',
	...DEFAULT_OMIT_CONTACT_RESELLER,
};

export const RTL = {
	id: RESELLER_IDS.RTL,
	trustedPartyId: TRUSTED_PARTY_IDS.RTL,
	displayName: 'RTL',
	...DEFAULT_OMIT_CONTACT_RESELLER,
};

export const KPN = {
	id: RESELLER_IDS.KPN,
	trustedPartyId: TRUSTED_PARTY_IDS.KPN,
	displayName: 'KPN',
	...DEFAULT_OMIT_CONTACT_RESELLER,
};

export const ZIGGO = {
	id: RESELLER_IDS.ZIGGO,
	trustedPartyId: TRUSTED_PARTY_IDS.ZIGGO,
	displayName: 'Ziggo',
	...DEFAULT_OMIT_CONTACT_RESELLER,
};

export const DELTA = {
	id: RESELLER_IDS.DELTA,
	trustedPartyId: TRUSTED_PARTY_IDS.DELTA,
	displayName: 'Delta',
	...DEFAULT_OMIT_CONTACT_RESELLER,
};

export const RESELLERS = [
	NPL,
	{
		id: RESELLER_IDS.KPN,
		displayName: 'KPN',
		...DEFAULT_OMIT_CONTACT_RESELLER,
	},
	{
		id: RESELLER_IDS.ZIGGO,
		displayName: 'Ziggo',
		...DEFAULT_OMIT_CONTACT_RESELLER,
	},
	TMOBILE,
	{
		id: RESELLER_IDS.APPLE,
		displayName: 'Apple',
		...DEFAULT_OMIT_CONTACT_RESELLER,
	},
	{
		id: RESELLER_IDS.GOOGLE,
		displayName: 'Google',
		...DEFAULT_OMIT_CONTACT_RESELLER,
	},
	RTLXL,
	SAMSUNG,
	{
		id: RESELLER_IDS.TMOBILE_GOGOGODEALS,
		displayName: 'Odido',
	},
	RTL,
];

export const TRUSTED_PARTIES = [TMOBILE, RTL, KPN, ZIGGO, DELTA];
