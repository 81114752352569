import {
	trackPageViews,
	trackClicks,
	refreshTrackClicks,
	trackErrors,
	trackStructEvent,
} from '@rtl_nl/rtl-snowplow';
import { analyticsByRoute, analyticsOnboardingByRoute } from '../../helpers/registration';
import { addToGlobalContexts as addToGlobalContextsSnowplow } from '@rtl_nl/rtl-snowplow';

const APP_ID = 'onboarding.videoland.com';

const mapEnv = { production: 'prod', development: 'dev', test: 'test', acceptance: 'acc' };

const getDataByRoute = ({ pageNamePlatform, location } = {}) => {
	const allAnalyticsByRoute = { ...analyticsByRoute, ...analyticsOnboardingByRoute };
	const newLocation = location ?? window.location.pathname;
	return pageNamePlatform ? pageNamePlatform : allAnalyticsByRoute[newLocation.replace(/\/+$/, '')];
};

const getContextData = ({ pageNamePlatform } = {}) => {
	const data = {
		appId: APP_ID,
		enableExperimentalData: true,
	};

	const pageName = getDataByRoute({ pageNamePlatform });

	if (pageName) {
		data.pageName = typeof pageName === 'string' ? pageName : pageName.pageName;
	}

	return data;
};

export const getPlatformData = ({ pageNamePlatform, location } = {}) => {
	const pageName = getDataByRoute({ pageNamePlatform, location });

	if (!pageName) {
		return null;
	}

	return {
		pageName: typeof pageName === 'string' ? pageName : pageName.pageName,
		platformName: 'videolandweb',
		language: 'nl-NL',
		environment: mapEnv[__ENVIRONMENT__],
		brandName: 'videoland',
	};
};

export const initatePageViewTracking = async ({ pageNamePlatform, rtlPurchase, rtlUser } = {}) => {
	const data = getContextData({ pageNamePlatform });

	await trackPageViews({ pageName: data.pageName, ...data, rtlPurchase, rtlUser });
};

export const initateLinkClickTracking = async () => {
	await trackClicks({
		appId: APP_ID,
		brandName: document.title ? document.title : APP_ID,
	});
};

export const refreshLinkClickTracking = async () => {
	await refreshTrackClicks();
};

export const initiateErrorTracking = async (error) => {
	if (__CLIENT__) {
		if (error) {
			await trackErrors({ appId: APP_ID, customError: error });
		} else {
			await trackErrors({ appId: APP_ID });
		}
	}
};

export const initiateTrackStructEvent = async (data) => {
	const commonData = getContextData();
	await trackStructEvent({ ...data, ...commonData });
};

export const addToGlobalContexts = async (data) => {
	await addToGlobalContextsSnowplow({ ...data, appId: APP_ID });
};
