import { analyticsByRoute } from '../helpers/registration';
import {
	initatePageViewTracking,
	getPlatformData,
	addToGlobalContexts,
} from '../components/analytics/Snowplow';
import { useEffect } from 'react';
import { matchPath, useLocation } from 'react-router';
import {
	ROUTE_ACTIVATE_VOUCHER,
	ROUTE_ACTIVATE_PLAN,
	ROUTE_ACTIVATED_PLAN,
} from '../constants/Routes';
import { removeFromGlobalContexts } from '@rtl_nl/rtl-snowplow';
import { useSelector } from 'react-redux';

export default function useInitTrackPageView(isLoading) {
	const location = useLocation();
	const gigyaUser = useSelector((state) => state.authentication.gigyaUser);

	const data = analyticsByRoute[location.pathname.replace(/\/+$/, '')];

	const isVoucherPage = matchPath(location.pathname, ROUTE_ACTIVATE_VOUCHER);

	const isActivateOrFinalize = matchPath(location.pathname, {
		path: [ROUTE_ACTIVATE_PLAN, ROUTE_ACTIVATED_PLAN],
		exact: false,
		strict: false,
	});

	const locationToMap = isActivateOrFinalize?.path ?? location.pathname;

	useEffect(() => {
		const platformData = getPlatformData({ location: locationToMap });
		if (!platformData) return;

		addToGlobalContexts({
			rtlPlatform: { ...platformData },
		});
		return async () => {
			await removeFromGlobalContexts({ rtlPlatform: { ...platformData } });
		};
	}, [locationToMap]);

	useEffect(() => {
		if (data && !isVoucherPage && !isLoading)
			initatePageViewTracking({
				...(data.useRtlUser && gigyaUser?.UID && { rtlUser: { rtlUserId: gigyaUser.UID } }),
			});
	}, [data, isLoading, isVoucherPage, gigyaUser.UID]);
}
