export const TIER_FEATURE_IDS = {
	UNLIMITED_ACCESS: 'UNLIMITED_ACCESS',
	AD_FREE: 'AD_FREE',
	SCREENS_AT_ONCE: 'SCREENS_AT_ONCE',
	DOWNLOAD_TO_GO: 'DOWNLOAD_TO_GO',
	LIVE_TV: 'LIVE_TV',
};

export const TIER_IDS = {
	FREE: 'FREE',
	STANDARD: 'STANDARD',
	LEGACY: 'LEGACY',
	PREMIUM: 'PREMIUM',
	ESSENTIAL: 'ESSENTIAL',
	STANDARD_LONG_TERM_CONTRACT_12M: 'PlusLongTermContract12M',
};

export const NO_TIER_ANALYTICS_NAME = 'Unknown tier';

export const TIER_RATEPLANS_DEFAULT = {
	STANDARD_PROMO: 'videoland-plus-2-weken',
	STANDARD: 'videoland-plus',
	PREMIUM_PROMO: 'videoland-premium-2-weken',
	PREMIUM: 'videoland-premium',
	ESSENTIAL_PROMO: 'videoland-basis-2-weken',
	ESSENTIAL: 'videoland-basis',
	FREE: 'videoland-tvgemist',
};

export const TIER_RATEPLANS_BLACKFRIDAY = [
	TIER_RATEPLANS_DEFAULT.ESSENTIAL,
	TIER_RATEPLANS_DEFAULT.ESSENTIAL_PROMO,
	TIER_RATEPLANS_DEFAULT.STANDARD,
	TIER_RATEPLANS_DEFAULT.STANDARD_PROMO,
];

export const TIER_IDS_NOPROMO = {
	ESSENTIAL: 'BasisNoPromo',
	STANDARD: 'PlusNoPromo',
	PREMIUM: 'PremiumNoPromo',
};

export const TIER_PLANS_NOPROMO = [
	TIER_IDS_NOPROMO.ESSENTIAL,
	TIER_IDS_NOPROMO.STANDARD,
	TIER_IDS_NOPROMO.PREMIUM,
];

export const TIER_RATEPLANS = Object.assign({}, TIER_RATEPLANS_DEFAULT, {
	STANDARD_LONG_TERM_CONTRACT_12M: '12-maanden-ltc-plus',
});

export const TIER_RATEPLAN_SLUGS = {
	[TIER_IDS.FREE]: {
		promo: TIER_RATEPLANS.FREE,
		evergreen: TIER_RATEPLANS.FREE,
	},
	[TIER_IDS.STANDARD]: {
		promo: TIER_RATEPLANS.STANDARD_PROMO,
		evergreen: TIER_RATEPLANS.STANDARD,
	},
	[TIER_IDS.ESSENTIAL]: {
		promo: TIER_RATEPLANS.ESSENTIAL_PROMO,
		evergreen: TIER_RATEPLANS.ESSENTIAL,
	},
	[TIER_IDS.PREMIUM]: {
		promo: TIER_RATEPLANS.PREMIUM_PROMO,
		evergreen: TIER_RATEPLANS.PREMIUM,
	},
	[TIER_IDS.STANDARD_LONG_TERM_CONTRACT_12M]: {
		discounted: TIER_RATEPLANS.STANDARD_LONG_TERM_CONTRACT_12M,
	},
};

export const RATEPLAN_TIERID_MAP = generateRateplanTierMap();

// Pre-generated this map for easy access
export function generateRateplanTierMap() {
	return Object.keys(TIER_RATEPLAN_SLUGS).reduce((acc, val) => {
		const promoRateplan = TIER_RATEPLAN_SLUGS[val].promo;
		const evergreenRateplan = TIER_RATEPLAN_SLUGS[val].evergreen;
		const discountedRateplan = TIER_RATEPLAN_SLUGS[val].discounted;
		if (discountedRateplan) acc[discountedRateplan] = val;
		if (promoRateplan) acc[promoRateplan] = val;
		if (evergreenRateplan) acc[evergreenRateplan] = val;
		return acc;
	}, {});
}

export const TIERS_SORT_ORDER = [TIER_IDS.ESSENTIAL, TIER_IDS.STANDARD, TIER_IDS.PREMIUM];

const { evergreenRateplans, promoRateplans } =
	getRateplanSeparationFromRateplanMap(TIER_RATEPLAN_SLUGS);

export const EVERGREEN_RATEPLANS = evergreenRateplans;
export const PROMO_RATEPLANS = promoRateplans;

export function getRateplanSeparationFromRateplanMap(rateplanMap) {
	let promoRateplans = [];
	let evergreenRateplans = [];
	let discountedRateplans = [];

	Object.keys(rateplanMap).forEach((key) => {
		const { promo, evergreen, discounted } = rateplanMap[key];
		if (evergreen) evergreenRateplans.push(evergreen);
		if (promo) promoRateplans.push(promo);
		if (discounted) discountedRateplans.push(discounted);
	}, []);

	return { promoRateplans, evergreenRateplans, discountedRateplans };
}

export const TIER_FREE = {
	id: TIER_IDS.FREE,
	pricing: {
		recurring: 0,
	},
};
